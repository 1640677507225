import React from 'react'
import { navigate } from '@reach/router'
import { Link } from 'gatsby'
import { ButtonBase } from '@material-ui/core'
import { colors, s, globals } from '../../style'
import { url } from '../../utils'

const Links = ({ language, links, theme, menuOpen }) => {
  return (
    <div css={[sLinks, { opacity: menuOpen ? 0 : 1 }]}>
      {links.map((link, id) => (
        <ButtonBase
          key={id}
          css={[sButtonBase(theme), links.length > 6 && sDecreaseSpacing]}>
          {link.isSection ? (
            <div
              onClick={() => {
                navigate(
                  url(
                    language,
                    link.page_slug,
                    false,
                    link.isSection,
                    link.absolute
                  )
                )
              }}
              onKeyPress={() => {
                navigate(
                  url(
                    language,
                    link.page_slug,
                    false,
                    link.isSection,
                    link.absolute
                  )
                )
              }}
              role="button"
              tabIndex={0}
              className="link">
              {link.text}
            </div>
          ) : (
            <Link
              to={url(
                language,
                link.page_slug,
                false,
                link.isSection,
                link.absolute
              )}
              activeClassName="activeLink"
              className={id === 0 ? 'link linkMax' : 'link'}>
              {link.text}
            </Link>
          )}
        </ButtonBase>
      ))}
    </div>
  )
}

const sLinks = {
  transition: 'opacity 0.4s ease-in-out',
  [s.md_down]: {
    display: 'none',
  },

  [s.lg]: {
    paddingRight: '0.5rem',
    paddingLeft: globals.menu.height.scrolled.desktop / 2,
  },
  [s.lg_only]: { paddingLeft: 0 },
  '.link': {
    transition:
      'color 0.25s ease-in-out, background-color 0.25s ease-in-out, padding 0.25s ease-in-out',
    fontSize: 14,
    fontWeight: 700,
    padding: '0.875rem 1.5rem',
    borderRadius: 50,
    textTransform: 'uppercase',
  },
}

const sButtonBase = (theme) => ({
  span: { color: `${colors[theme].container} !important` },
  borderRadius: '1.5rem !important',
  margin: '0 0.5rem !important',
})

const sDecreaseSpacing = {
  [s.md]: { margin: '0 !important' },
  [s.xl]: { margin: '0 0.5rem !important' },
}

export default Links
