import React, { useState } from 'react'
import { navigate } from '@reach/router'
import { Link } from 'gatsby'
import { alpha, colors, s } from '../../style'

import flag_pl from '../../assets/flag_pl'
import flag_en from '../../assets/flag_en'
import flag_ru from '../../assets/flag_ru'
import flag_hu from '../../assets/flag_hu'
import flag_sk from '../../assets/flag_sk'

export const languages = {
  pl: {
    flag: flag_pl,
    link: '/pl',
    name: 'Polski',
  },
  en: {
    flag: flag_en,
    link: '/en',
    name: 'English',
  },
  hu: {
    flag: flag_hu,
    link: '/hu',
    name: 'Magyar',
  },
  ru: {
    flag: flag_ru,
    link: '/ru',
    name: 'Pусский',
  },
  sk: {
    flag: flag_sk,
    link: '/sk',
    name: 'Svenska',
  },
}

const MiniMenuTop = ({ miniLinksData, language, dispatch }) => {
  const [isLangsOpen, setLangsOpen] = useState(false)
  return (
    <div css={sStyle}>
      {miniLinksData.map((link, id) => (
        <Link key={id} css={sLink} to={`/${link.language}/${link.page_slug}`}>
          {link.text}
        </Link>
      ))}
      {/* <div
        onClick={() => setLangsOpen(!isLangsOpen)}
        css={{
          transition: 'background-color 0.3s ease-out',
          cursor: 'pointer',
          position: 'relative',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: 'max-content',
          height: 34,
          padding: '0 3rem',
          color: colors.main,
          fontWeight: 'bolder',
          fontSize: '0.75rem',
          svg: { width: 24, height: 24 },
          span: { marginRight: '0.5rem' },
          '&:hover': {
            backgroundColor: '#bee0e6',
          },
        }}>
        <ArrowDropDownIcon />
        <span>{language.toUpperCase()}</span>
        {languages[language].flag}
        <div
          css={[
            !isLangsOpen && { opacity: 0, pointerEvents: 'none' },
            {
              transition: 'opacity 0.3s ease-out',
              display: 'flex',
              flexDirection: 'column',
              position: 'absolute',
              bottom: 0,
              left: '50%',
              transform: 'translate(-50%, 100%)',
            },
          ]}>
          {Object.values(languages).map((lang, id) => (
            <div
              onClick={() => {
                navigate(lang.link)
                setLangsOpen(false)
              }}
              onKeyPress={() => {
                navigate(lang.link)
                setLangsOpen(false)
              }}
              tabIndex={0}
              role="button"
              key={id}
              css={{
                transition: 'background-color 0.3s ease-out',
                span: { marginRight: 0 },
                height: 43,
                display: 'flex',
                alignItems: 'center',
                border: `1px solid ${colors.main.concat(alpha[30])} !important`,
                padding: '0 3.8rem',
                backgroundColor: `#DFF2F6`,
                svg: { width: 24, height: 24 },
                '&:hover': {
                  backgroundColor: '#bee0e6',
                },
                '&:last-of-type': {
                  borderTop: 'none !important',
                },
              }}>
              <span>{lang.name}</span>
            </div>
          ))}
        </div>
      </div>*/}
    </div>
  )
}

const sStyle = {
  [s.md_down]: { display: 'none' },
  position: 'fixed',
  top: 0,
  right: 0,
  display: 'flex',
  borderRadius: '0 0 0 2rem',
  zIndex: 1002,
  background: `#DFF2F6`,
  // overflow: 'hidden',
}

const sLink = {
  transition: 'background-color 0.3s ease-out',
  fontSize: '0.75rem',
  padding: '9px 3rem 0.5rem 3rem',
  textTransform: 'uppercase',
  color: colors.main,
  borderRight: `1px solid ${colors.main.concat(alpha[30])}`,
  '&:hover': {
    backgroundColor: '#bee0e6',
  },
}

export default MiniMenuTop
