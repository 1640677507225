import { globals } from './globals'
// import { colors } from './colors'
import { s } from '../constants/sizes'
import { colors } from './colors'
// import { alpha } from '../constants/alpha'

export const sHtml = {
  maxWidth: globals.maxWidth,
  overflow: 'hidden',
  paddingBottom: '4rem',
  [s.sm_down]: {
    // marginTop: globals.spacing.outside.mobile,
    padding: globals.spacing.inside.mobile,
    // boxShadow: globals.shadows.sections.mobile,
    // borderRadius: globals.roundness.sections.mobile,
  },
  [s.md]: {
    margin: '0 auto',
    // marginTop: globals.spacing.between.desktop,
    padding: `0 ${globals.spacing.inside.desktop}`,
    // boxShadow: globals.shadows.sections.desktop,
    // borderRadius: globals.roundness.sections.desktop,
  },

  p: {
    opacity: 0.6,
    lineHeight: '1.62em',
    margin: '1rem 0',
  },
  color: colors.main,
  'h2, h6': {
    [s.sm_down]: { fontSize: '1rem' },
    [s.md]: { fontSize: '1.25rem' },
    color: colors.secondary,
  },
}
