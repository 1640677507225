export const url = (
  language,
  page,
  section,
  isSection,
  absolute,
  absoluteSection
) =>
  absoluteSection
    ? `/${section}`
    : isSection
    ? section
      ? (!absolute ? `/${language}` : '') + (section ? `#${section}` : '')
      : (!absolute ? `/${language}` : '') + (page ? `#${page}` : '')
    : (!absolute ? `/${language}` : '') +
      (page ? `/${page}` : '') +
      (section ? `#${section}` : '')
