import React from 'react'
import { Link } from 'gatsby'
import { navigate } from '@reach/router'
import { url } from '../../utils'

import { s, globals, colors } from '../../style'

const MenuFullScreen = ({
  language,
  menuOpen,
  theme,
  showIndex = false,
  blur = false,
  dispatch,
  ...props
}) => {
  // console.log(props.miniLinks)

  const links = props.links?.concat(props.miniLinks)

  return (
    <div
      css={[
        sContainer,
        !menuOpen && sContainer.hidden,
        sContainerBackground(theme, blur),
      ]}>
      {/* <div css={{ display: 'flex', width: '100%' }}>
        {links
          .filter((_, id) => id === 0)
          .map((page) => (
            <Link
              to={url(
                language,
                page.page_slug,
                null,
                page.isSection,
                page.absolute
              )}
              onClick={() => {
                navigate(
                  url(
                    language,
                    page.page_slug,
                    null,
                    page.isSection,
                    page.absolute
                  )
                )
                dispatch({ type: 'TOGGLE_MENU' })
              }}
              className="linkL"
              activeClassName="linkLActive">
              {page.text}
            </Link>
          ))}
      </div> */}
      <div css={[sTextContainer]}>
        {links
          // .filter((link, id) => id > 0)
          .filter((link) => (showIndex ? true : !link.isIndex))
          .sort((a, b) => a.order - b.order)
          .map((page, id) => (
            <div
              key={id}
              css={[
                sLinkContainer(theme),
                sMenuReactOnMenu(menuOpen),
                { marginBottom: '3rem' },
                { transition: `opacity 0.3s ease-in ${id * 0.07}s` },
              ]}>
              <div
                css={{
                  [s.sm_down]: { width: '100%' },
                  [s.md]: {
                    margin: '0 auto',
                    width: '80%', // was  50%
                  },
                }}>
                <Link
                  to={url(
                    language,
                    page.page_slug,
                    null,
                    page.isSection,
                    page.absolute
                  )}
                  onClick={() => {
                    navigate(
                      url(
                        language,
                        page.page_slug,
                        null,
                        page.isSection,
                        page.absolute
                      )
                    )
                    dispatch({ type: 'TOGGLE_MENU' })
                  }}
                  className="linkL"
                  activeClassName="linkLActive"
                  css={[
                    id >= links.length - 2 && {
                      '&:nth-last-of-type(2), &:nth-last-of-type(1)': {
                        fontWeight: '400 !important',
                      },
                    },
                    id === 0 && {
                      transition: 'filter 0.3s ease-out !important',
                      backgroundColor: colors.main,
                      padding: '1rem 2rem',
                      borderRadius: '4rem',
                      color: colors.white + ' !important',
                      whiteSpace: 'nowrap',
                      '&:hover': {
                        backgroundColor: colors.main,
                        filter: 'brightness(1.25)',
                      },
                      [s.xs]: {
                        textAlign: 'center',
                        display: 'flex',
                        fontSize: '1.5rem !important',
                        width: 'calc(100% - 2rem)',
                      },
                      [s.sss]: {
                        fontSize: '1.25rem !important',
                      },
                    },
                  ]}>
                  {page.text}
                </Link>
                <div css={sSmallLinksContainer}>
                  {page.sections &&
                    page.sections.map((section, id) => (
                      <div
                        onClick={() => {
                          navigate(
                            url(
                              language,
                              page.page_slug,
                              section.section_slug,
                              page.isSection,
                              page.absolute,
                              section.absolute
                            )
                          )
                          dispatch({ type: 'TOGGLE_MENU' })
                        }}
                        onKeyPress={() => {
                          navigate(
                            url(
                              language,
                              page.page_slug,
                              section.section_slug,
                              page.isSection,
                              page.absolute,
                              section.absolute
                            )
                          )
                          dispatch({ type: 'TOGGLE_MENU' })
                        }}
                        role="button"
                        tabIndex={0}
                        className="linkS"
                        key={id}>
                        {section.text}
                      </div>
                    ))}
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  )
}

const sContainerBackground = (theme, blur) => ({
  background: globals.menu.background[theme],
  backdropFilter: blur ? 'blur(30px)' : 'none',
})

const sMenuReactOnMenu = (menuOpen) =>
  menuOpen
    ? {
        opacity: 1,
        // [s.sm_down]: { paddingLeft: '0rem' }, // 2 rem
        // [s.md]: { paddingLeft: '0rem' }, // 6rem
      }
    : {
        opacity: 0,
        // [s.sm_down]: { paddingLeft: '0rem' },
        // [s.md]: { paddingLeft: '0rem' },
      }

const sContainer = {
  zIndex: 10,
  transition: 'opacity 0.3s ease-out',
  overflow: 'scroll',
  top: 0,
  left: 0,
  position: 'fixed',
  width: '100vw',
  height: '100vh',
  [s.sm_down]: {
    paddingTop: globals.menu.height.mobile,
  },
  [s.md]: {
    paddingTop: globals.menu.height.desktop,
  },
  hidden: {
    opacity: 0,
    pointerEvents: 'none',
  },
}

const sTextContainer = {
  maxWidth: 1280,
  width: '100vw',
  position: 'absolute',

  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',

  [s.sm_down]: {
    flexDirection: 'column',
    top: globals.menu.height.mobile + 64,
    left: 0,
  },
  [s.md]: {
    top: globals.menu.height.desktop,
    left: '50%',
    transform: 'translateX(-50%)',
    paddingTop: '8rem',
    textAlign: 'center',
  },
  // [s.lg]: {
  //   top: '50%',
  //   left: '50%',
  //   transform: 'translate(-50%, -50%)',
  // },
}

const colorTransitionOnLinks = 'color 0.25s ease-in-out'

const sLinkContainer = (theme) => ({
  paddingLeft: '2rem',
  width: '100%',
  // paddingLeft: '10%',
  [s.md]: { width: '50%' }, // was 50
  '.linkL': {
    cursor: 'pointer',
    transition: colorTransitionOnLinks,
    fontWeight: 700,
    // textTransform: 'uppercase',
    lineHeight: '1em',
    [s.sm_down]: { fontSize: '2rem', letterSpacing: -2 },
    [s.md]: { fontSize: '3rem', letterSpacing: -3 },
    color: colors[theme].menuFullScreen.page.initial,
    '&:hover': {
      [s.hover]: { color: colors[theme].menuFullScreen.page.hover },
    },
  },
  '.linkS': {
    cursor: 'pointer',
    transition: colorTransitionOnLinks,
    fontSize: 18,
    fontWeight: 400,
    paddingBottom: '1rem',
    lineHeight: '1em',
    letterSpacing: -1,
    color: colors[theme].menuFullScreen.section.initial,
    '&:hover': {
      [s.hover]: { color: colors[theme].menuFullScreen.section.hover },
    },
  },
  '.linkLActive': {
    transition: colorTransitionOnLinks,
    color: colors[theme].menuFullScreen.active.initial,
    '&:hover': {
      [s.hover]: { color: colors[theme].menuFullScreen.active.hover },
    },
  },
})

const sSmallLinksContainer = {
  display: 'flex',
  flexDirection: 'column',
  [s.sm_down]: { marginTop: '1rem' },
  [s.md]: { marginTop: '1rem' },
}

export default MenuFullScreen
