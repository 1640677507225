import React from 'react'
// import PropTypes from 'prop-types'
import { navigate } from '@reach/router'
import { graphql, useStaticQuery } from 'gatsby'
// import { StateContext, DispatchContext, initialState } from '../../context'

// COMPONENTS
import Logo from './Logo'
import Links from './Links'
import MenuButton from './MenuButton'
import MenuFullScreen from './MenuFullScreen'
import logosvg from '../assets/logo'
import LanguageButton from './LanguageButton'

// STYLES
import { s, globals } from '../style'
import {
  sMenuColorsInitial,
  sMenuColorsHover,
  sMenuColorsScroll,
  sMenuColorsBlur,
  sMenuScrollHeight,
} from './utils'
import MiniMenuTop from './MiniMenuTop/MiniMenuTop'

const MenuTopGatsby = ({
  scrollTopHoverEffect = false,
  scrollColorEffect = true,
  blurEffect = false,
  scrollHeightEffect = true,
  transparentOnMenuOpen = true,
  dispatch,
  language,
  theme,
  isScrollTop,
  menuOpen,
}) => {
  // const state = useContext(StateContext)
  // const dispatch = useContext(DispatchContext)
  // const language = state?.language ? state.language : initialState?.language
  // const theme = state?.theme ? state.theme : initialState?.theme
  // const isScrollTop = state?.isScrollTop
  // const menuOpen = state?.menuOpen

  const data = useStaticQuery(graphql`
    {
      menus: allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/content/menus/" } }
      ) {
        edges {
          node {
            frontmatter {
              language
              mini_menu {
                title
                page_slug
              }
              pages {
                page_slug
                page_title
                isSection
                sections {
                  section_slug
                  section_title
                  absolute
                }
              }
            }
          }
        }
      }
      logo: file(absolutePath: { regex: "/media/logo.svg/" }) {
        publicURL
      }
    }
  `)

  const linksData = data.menus.edges
    .filter((e) => e.node.frontmatter?.pages?.length > 0)
    .filter((e) => e.node.frontmatter?.language === language)
    .map((e) =>
      e.node.frontmatter.pages.map((page) => ({
        language: e.node.frontmatter.language,
        text: page.page_title,
        page_slug: page.page_slug,
        isSection: page.isSection,
        sections: page.sections?.map((section, id) => ({
          text: section.section_title,
          section_slug: section.section_slug,
          absolute: section.absolute,
        })),
      }))
    )
    .reduce((a, b) => a.concat(b), [])

  const miniLinksData = data.menus.edges
    .filter((e) => e.node.frontmatter?.pages?.length > 0)
    .filter((e) => e.node.frontmatter?.language === language)
    .map((e) =>
      e.node.frontmatter.mini_menu.map((page) => ({
        language: e.node.frontmatter.language,
        text: page.title,
        page_slug: page.page_slug,
        isSection: false,
        sections: [],
      }))
    )
    .reduce((a, b) => a.concat(b), [])

  // console.log('linksData', linksData)
  // console.log('miniLinksData', miniLinksData)

  return (
    <>
      <MenuFullScreen
        language={language}
        links={linksData}
        miniLinks={miniLinksData}
        menuOpen={menuOpen}
        theme={theme}
        dispatch={dispatch}
      />
      <MiniMenuTop
        miniLinksData={miniLinksData}
        language={language}
        dispatch={dispatch}
      />
      <nav
        css={[
          sMenu,
          sMenuColorsInitial(theme),
          scrollTopHoverEffect && sMenuColorsHover(theme),
          scrollColorEffect &&
            !blurEffect &&
            !isScrollTop &&
            sMenuColorsScroll(theme),
          blurEffect && sMenuColorsBlur(theme),
          scrollHeightEffect && !isScrollTop && sMenuScrollHeight,
          transparentOnMenuOpen &&
            menuOpen && {
              backgroundColor: 'transparent',
              backdropFilter: 'blur(16px)',
            },
        ]}>
        <div
          onClick={() => {
            navigate(`/${language}`)
            dispatch({ type: 'SET_MENU', payload: false })
          }}
          onKeyPress={() => {
            navigate(`/${language}`)
            dispatch({ type: 'SET_MENU', payload: false })
          }}
          role="button"
          tabIndex={0}>
          <Logo
            jsvg={logosvg}
            theme={theme}
            menuOpen={menuOpen}
            shouldReverse={scrollTopHoverEffect || scrollColorEffect}
            isScrollTop={isScrollTop}
            themeBased
          />
        </div>
        <div css={sRightPart}>
          <Links
            links={linksData}
            theme={theme}
            menuOpen={menuOpen}
            language={language}
          />
          <LanguageButton
            theme={theme}
            dispatch={dispatch}
            language={language}
          />
          {/* <ThemeButton
            theme={theme}
            dispatch={dispatch}
            isScrollTop={isScrollTop}
            menuOpen={menuOpen}
          /> */}
          {/* <div>
            LANG: {language}
            <button
              onClick={() => dispatch({ type: 'SET_LANGUAGE', payload: 'en' })}>
              Set EN
            </button>
            <button
              onClick={() => dispatch({ type: 'SET_LANGUAGE', payload: 'pl' })}>
              Set PL
            </button>
          </div> */}
          <MenuButton
            menuOpen={menuOpen}
            dispatch={dispatch}
            theme={theme}
            isScrollTop={isScrollTop}
          />
        </div>
      </nav>
    </>
  )
}

const sMenu = {
  transition: 'background-color 0.25s ease-in, height 0.25s ease-out',
  zIndex: 1001,
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100vw',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  [s.sm_down]: {
    height: globals.menu.height.mobile,
    padding: `0 2rem`,
  },
  [s.md]: {
    height: globals.menu.height.desktop,
    padding: `0 ${globals.menu.height.mobile / 2}px`,
  },
}

const sRightPart = {
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
}

export default MenuTopGatsby
