import React from 'react'
export default (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 512 512"
    xmlSpace="preserve">
    <circle fill="#FFDA44" cx="256" cy="256" r="256" />
    <g>
      <path
        fill="#0052B4"
        d="M200.349,222.609h309.484C493.47,97.002,386.067,0,256,0c-19.114,0-37.733,2.113-55.652,6.085
		v216.524H200.349z"
      />
      <path
        fill="#0052B4"
        d="M133.565,222.608V31.127C63.271,69.481,12.95,139.832,2.167,222.609h131.398V222.608z"
      />
      <path
        fill="#0052B4"
        d="M133.564,289.391H2.167c10.783,82.777,61.104,153.128,131.398,191.481L133.564,289.391
		L133.564,289.391z"
      />
      <path
        fill="#0052B4"
        d="M200.348,289.392v216.523C218.267,509.887,236.886,512,256,512
		c130.067,0,237.47-97.002,253.833-222.609H200.348V289.392z"
      />
    </g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
)
