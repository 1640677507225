// COLORS DEFINITION
// For main colors use Shades at https://color.adobe.com/create/color-wheel

import { alpha } from '../constants/alpha'

export const colors = {
  white: '#ffffff', // constant
  black: '#000000', // constant
  mainLighter: '#1DCAE0', // usually 100% Light
  mainLight: '#18A7BA', // usually +15% Light
  main: '#138494', // main theme color - used for coloring elements, buttons and typography
  brand: '#138494', // brand color for comparsion it should be same as on variant of main color
  mainDark: '#0B4C54', // usually -10% Light
  mainDarker: '#002D33', // usually -25% Light
  secondary: '#9E9E9E', // contrasting color002D33

  light: {
    mainLighter: '#1DCAE0', // usually 100% Light
    mainLight: '#18A7BA', // usually +15% Light
    main: '#138494', // main theme color - used for coloring elements, buttons and typography
    brand: '#138494', // brand color for comparsion it should be same as on variant of main color
    mainDark: '#0B4C54', // usually -10% Light
    mainDarker: '#002D33', // usually -25% Light
    secondary: '#9E9E9E', // contrasting color002D33
    shadow: '#002D33',
    text: '#333333', // parent all typography color
    container: '#F2FDFF', // usually a bit lighter than the background
    background: ['#F2FDFF', '#CFE3E6'], // defines body background gradient. For more options see ./globals.background.light
    logo: '#138494',
    logoReversed: '#138494',
    menu: {
      initial: {
        back: '#EFFBFDCC',
        // back: 'transparent',
        link: {
          back: 'transparent',
          text: '#138494',
          hover: {
            back: '#138494'.concat(alpha[16]),
            text: '#138494',
          },
        },
        activeLink: {
          back: '#138494',
          text: '#ffffff',
          hover: {
            back: '#18A7BA',
            text: '#ffffff',
          },
        },
      },
      hovered: {
        // as well as scrolled
        back: '#EFFBFD',
        link: {
          back: 'transparent',
          text: '#138494'.concat(alpha[100]),
          hover: {
            back: '#138494'.concat(alpha[16]),
            text: '#138494'.concat(alpha[100]),
          },
        },
        activeLink: {
          back: '#138494'.concat(alpha[100]),
          text: '#ffffff',
          hover: {
            text: '#138494',
            back: '#138494'.concat(alpha[30]),
          },
        },
      },
    },
    menuFullScreen: {
      background: ['#F2FDFF', '#CFE3E6'],
      page: {
        initial: '#3C9EAB'.concat(alpha[100]),
        hover: '#1DCAE0',
      },
      section: {
        initial: '#168A9A'.concat(alpha[100]),
        hover: '#1DCAE0'.concat(alpha[100]),
      },
      active: {
        initial: '#0B4C54',
        hover: '#18A7BA',
      },
    },
    buttons: {
      initial: {
        angle: 45,
        background: ['#18A7BA', '#0B4C54'],
        text: '#ffffff',
      },
      reversed: {
        angle: 45,
        background: ['#F2FDFF', '#CFE3E6'],
        text: '#0B4C54',
      },
    },
    footer: {
      background: ['#939697', '#333333'],
      angle: 135,
      text: '#ffffff',
    },
  },

  dark: {
    mainLighter: '#8ee5f0', // usually 100% Light
    mainLight: '#4ad5e8', // usually +15% Light
    main: '#1DCAE0', // main theme color - used for coloring elements, buttons and typography
    brand: '#1DCAE0', // brand color for comparsion it should be same as on variant of main color
    mainDark: '#138494', // usually -10% Light
    mainDarker: '#0B4C54', // usually -25% Light
    secondary: '#939697', // contrasting color002D33
    shadow: '#002D33',
    text: '#fafafa', // parent all typography color
    textReversed: '#333333',
    container: '#333333', // usually a bit lighter than the background
    background: ['#333333', '#1a1a1a'], // defines body background gradient. For more options see ./globals.background.dark
    logo: '#1DCAE0',
    logoReversed: '#1DCAE0',
    menu: {
      initial: {
        back: 'transparent',
        link: {
          back: 'transparent',
          text: '#1DCAE0',
          hover: {
            back: '#1DCAE0'.concat(alpha[30]),
            text: '#1DCAE0',
          },
        },
        activeLink: {
          back: '#1DCAE0',
          text: '#1a1a1a',
          hover: {
            back: '#1DCAE0',
            text: '#1a1a1a',
          },
        },
      },
      hovered: {
        // as well as scrolled
        back: '#333333'.concat(alpha[100]),
        link: {
          back: 'transparent',
          text: '#1DCAE0'.concat(alpha[100]),
          hover: {
            back: '#1DCAE0'.concat(alpha[30]),
            text: '#1DCAE0',
          },
        },
        activeLink: {
          back: '#1DCAE0',
          text: '#1a1a1a',
          hover: {
            back: '#1DCAE0'.concat(alpha[80]),
            text: '#1a1a1a',
          },
        },
      },
    },
    menuFullScreen: {
      background: ['#1a1a1a', '#333333'],
      page: {
        initial: '#1DCAE0',
        hover: '#ffffff'.concat(alpha[100]),
      },
      section: {
        initial: '#ffffff'.concat(alpha[60]),
        hover: '#ffffff'.concat(alpha[100]),
      },
      active: {
        initial: '#1DCAE0',
        hover: '#1DCAE0'.concat(alpha[60]),
      },
    },
    buttons: {
      initial: {
        angle: 45,
        background: ['#1DCAE0', '#1DCAE0'],
        text: '#333333',
      },
      reversed: {
        angle: 45,
        background: ['#333333', '#1a1a1a'],
        text: '#1DCAE0',
      },
    },
    footer: {
      background: ['#262626', '#1a1a1a'],
      angle: 135,
      text: '#ffffff',
    },
  },
}
