import React, { useState } from 'react'
import { navigate } from '@reach/router'
import { colors, s, alpha } from '../../style'
import flag_pl from '../../assets/flag_pl'
import flag_en from '../../assets/flag_en'
import flag_ru from '../../assets/flag_ru'
import flag_hu from '../../assets/flag_hu'
import flag_sk from '../../assets/flag_sk'

export const languages = {
  pl: {
    flag: flag_pl,
    link: '/pl',
    name: 'Polski',
  },
  en: {
    flag: flag_en,
    link: '/en',
    name: 'English',
  },
  // hu: {
  //   flag: flag_hu,
  //   link: '/hu',
  //   name: 'Magyar',
  // },
  // ru: {
  //   flag: flag_ru,
  //   link: '/ru',
  //   name: 'Pусский',
  // },
  // sk: {
  //   flag: flag_sk,
  //   link: '/sk',
  //   name: 'Svenska',
  // },
}

const LanguageButton = ({ dispatch, language, theme, footerStyle }) => {
  const [isLangsOpen, setLangsOpen] = useState(false)

  return (
    <div
      css={[sStyle(theme)]}
      onClick={() => setLangsOpen(!isLangsOpen)}
      role="button"
      tabIndex={0}>
      {languages[language].flag}
      <div
        css={[
          sLanguages,
          !isLangsOpen && { opacity: 0, pointerEvents: 'none' },
          footerStyle && {
            left: 'auto',
            right: 0,
            transform: 'translate(0%, 100%)',
            [s.sm_down]: { left: 0, transform: 'translate(0%, 100%)' },
          },
        ]}>
        {Object.values(languages).map((lang, id) => (
          <div
            key={id}
            onClick={() => {
              navigate(lang.link)
              setLangsOpen(false)
            }}
            onKeyPress={() => {
              navigate(lang.link)
              setLangsOpen(false)
            }}
            tabIndex={0}
            role="button"
            css={sLanguage}>
            {lang.name}
          </div>
        ))}
      </div>
    </div>
  )
}

const sLanguages = {
  width: 'max-content',
  transition: 'opacity 0.3s ease-out',
  display: 'flex',
  flexDirection: 'column',
  position: 'absolute',
  bottom: -16,
  left: '50%',
  transform: 'translate(-50%, 100%)',
  marginTop: '2rem',
  backgroundColor: `#DFF2F6`,
  overflow: 'hidden',
  borderRadius: '1.5rem',
  // border: `1px solid ${colors.main.concat(alpha[30])} !important`,
  boxShadow: `0px 4px 32px ${colors.black.concat(alpha[8])}`,
}

const sLanguage = {
  transition: 'color 0.3s ease-out, background-color 0.3s ease-out',
  padding: '1rem 3rem',
  textAlign: 'center',
  fontSize: '0.875rem',
  fontWeight: 'bolder',
  fontFamily: 'Montserrat',
  '&:hover': {
    color: 'white',
    backgroundColor: colors.main,
  },
}

const sStyle = (theme) => ({
  position: 'relative',
  cursor: 'pointer',
  marginRight: '1rem',
  transition:
    'color 0.25s ease-in, background-color 0.25s ease-in, padding 0.25s ease-in',
  fontSize: '1rem',
  borderRadius: '2rem',
  fontWeight: 700,
  padding: '0.5rem',
  lineHeight: '1em',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: 'transparent',
  color: colors[theme].menu.initial.activeLink.back,
  '&:hover': {
    [s.hover]: {
      backgroundColor: colors[theme].menu.initial.link.hover.back,

      color: colors[theme].menu.initial.link.text,
    },
  },
  svg: {
    height: 28,
    width: 28,
  },
})

// const sMenuScrolled = (theme) => ({
//   // padding: '0.5rem 1rem',
//   backgroundColor: 'transparent',
//   color: colors[theme].logoReversed,
//   '&:hover': {
//     [s.hover]: {
//       backgroundColor: colors[theme].menu.hovered.activeLink.back,
//       color: colors[theme].menu.hovered.activeLink.text,
//     },
//   },
// })

export default LanguageButton
