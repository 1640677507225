import { createTheme } from '@material-ui/core'
import { colors } from '../clever-ui-kit/style'
import { initialState } from '../context'

const theme = createTheme({
  palette: {
    type: initialState.theme || 'light',
    primary: { main: colors.main },
    secondary: { main: colors.secondary },
  },
  typography: { fontFamily: ['font1'] },
})
export default theme
